.error-boundary {
  .title {
    font-size: 2em;
    font-family: sans-serif;
    color: rgb(232, 59, 70);
    white-space: pre-wrap;
    margin: 0px 2rem 0.75rem 0px;
    flex: 0 0 auto;
    max-height: 50%;
    overflow: auto;
  }
  code {
    padding: 10px;
  }
}